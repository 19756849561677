import React, { useState, useEffect } from "react";
import "./styles.css";
import { Link, useLocation } from "react-router-dom";
import GODS from "../../../assets/Logos/gods4.0.png";

const Navbar = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isAfter8PM, setIsAfter8PM] = useState(false);
  const [isBootcampClicked, setIsBootcampClicked] = useState(false);
  const location = useLocation();

  // Reset bounce when returning to Home
  useEffect(() => {
    if (location.pathname === "/GODS") {
      setIsBootcampClicked(false);
    }
  }, [location.pathname]);

  const handleBootcampClick = () => {
    setIsBootcampClicked(true);
  };

  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

  useEffect(() => {
    const targetTime = new Date("2025-01-26T20:00:00");
    const checkTime = () => new Date() >= targetTime && setIsAfter8PM(true);
    
    checkTime();
    const interval = setInterval(checkTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <header className="header fixed top-0 left-0 w-full h-[4.5rem] shadow-md bg-[#f2f2f2] z-10">
      <nav className="nav container md:mt-[-8px] mt-0">
        <div className="nav__data h-full flex justify-between items-center">
          <Link to="/GODS">
            <img src={GODS} alt="Logo" width={200} />
          </Link>
          
          <div className={`nav__toggle ${isMenuVisible ? "show-icon" : ""}`} onClick={toggleMenu}>
            <i className={`ri-menu-line nav__toggle-menu ${isMenuVisible ? "hidden" : ""}`}></i>
            <i className={`ri-close-line nav__toggle-close ${isMenuVisible ? "" : "hidden"}`}></i>
          </div>
        </div>

        <div className={`${isMenuVisible ? "nav__menu show-menu" : "nav__menu"}`}>
          <ul className="nav__list">
            <li><Link to="/GODS" className="nav__link">Home</Link></li>
            <li><Link to="/GODS/Program" className="nav__link">Program</Link></li>
            <li><Link to="/GODS/AboutUs" className="nav__link">About</Link></li>
            <li><Link to="/GODS/ContactUs" className="nav__link">Contact</Link></li>
            <li>
              <Link
                to="/GODS/Bootcamp"
                onClick={handleBootcampClick}
                className={`px-4 py-2 rounded-full bg-gradient-to-r from-[#1d202b] to-[#0061a8] text-white font-bold transition-all shadow-md ${
                  location.pathname === "/GODS" && !isBootcampClicked ? "animate-bounce" : ""
                }`}
              >
                Bootcamp
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <ul className="nav__list hidden">
            <div className="Reg_buttom pointer">
              <li>
                <Link
                  to={isAfter8PM ? "/GODS/Registration" : "/GODS/ComingSoon"}
                  className="nav__link1"
                >
                  <span className="text-[#feda5e]">Registration</span>
                </Link>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;