import React from "react";
import styles from "../style";

import trainer1 from "../Program/trainers/1.png";
import trainer2 from "../Program/trainers/2.png";
import trainer3 from "../Program/trainers/3.png";
import trainer4 from "../Program/trainers/4.png";
import trainer5 from "../Program/trainers/5.png";

const images = [trainer1, trainer2, trainer3, trainer4, trainer5];

const ProjectCard = ({ src, title, description, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer noopener"
      className="relative overflow-hidden rounded-lg shadow-lg border border-[#2A0E61] transition-all duration-300 ease-in-out transform hover:scale-105"
    >
      <img
        src={src}
        alt={title}
        className="w-full object-contain h-[300px] grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3"
      />
      <div className="relative">
        <h1 className="text-2xl font-semibold text-white">{title}</h1>
        <p className={`${styles.paragraph} mt-2 font-bold`}>{description}</p>
      </div>
    </a>
  );
};

const PROJECTS = [
  {
    title: "Project 1",
    image: images[3],
    link: "https://drive.google.com/file/d/1J8vX7SVWIoncfr5Fr1T_gsJEYpiCX6xK/preview"
  },
  {
    title: "Project 2",
    image: images[0],
    link: "https://drive.google.com/file/d/1mRv4KcsNMYhmDdQRt2KDTzr49Umrtxhc/preview"
  },
  {
    title: "Project 3",
    image: images[1],
    link: "https://drive.google.com/file/d/1roISI5RGZNYEzmPffx0ybhDF66YqyY4O/preview"
  },
  {
    title: "Project 4",
    image: images[2],
    link: "https://example.com"
  },
  {
    title: "Project 5",
    image: images[4],
    link: "https://example.com"
  }
  // Add more projects as needed
];

const Workshops = () => {
  return (
    <section
      id="projects"
      className="flex flex-col items-center justify-center py-20"
    >
       <h1 className="text-center text-[40px] font-semibold text-transparent  bg-clip-text bg-gradient-to-r from-purple-500 to-cyan-500 mb-2 pt-6">
        Our Data Science Workshop: 
        
        </h1>
        <h1 className="text-center text-3xl font-bold text-black mb-8">
         
          Turning Insights into Impact
        </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-15 px-10 h-[80%]">
        {PROJECTS.map((project, index) => (
          <ProjectCard
            key={index}
            src={project.image}
            title={project.title}
            link={project.link}
          />
        ))}
      </div>
    </section>
  );
};

export default Workshops;
