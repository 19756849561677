import React, { useState } from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

const GoogleDriveVideo = () => {
  // Define your playlist videos with Google Drive IDs
  const videos = [
    { id: '1J8vX7SVWIoncfr5Fr1T_gsJEYpiCX6xK', title: 'Intoduction to Computer Vision' },
    { id: '1mRv4KcsNMYhmDdQRt2KDTzr49Umrtxhc', title: 'Introduction to DeepLearning with Pytorch' },
    { id: '1roISI5RGZNYEzmPffx0ybhDF66YqyY4O', title: 'Introduction to NLP' },
    // Add more videos as needed.
  ];

  const [selectedVideo, setSelectedVideo] = useState(null);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <Navbar/>
      <header className="py-4">
      <h1 className="text-center text-[20px] sm:text-[60px] font-semibold text-transparent  bg-clip-text bg-gradient-to-r from-[#1d202b] to-[#0061a8] mb-2 pt-24">
      Bootcamp recorded sessions
        </h1>
      </header>

      {/* Main Content */}
      <div className="px-6 py-16 flex items-center justify-center">
        <div className="w-full max-w-screen-2xl mx-auto px-6">
          <div className="flex flex-col md:flex-row gap-12 justify-center">
            {/* Playlist Container */}
            <div className="md:w-[30%] bg-white shadow-md rounded-lg p-6 min-h-[300px] flex flex-col justify-start">
              <h2 className="text-2xl font-semibold mb-12 ml-6">Sessions</h2>
              <ul className="space-y-6">
                {videos.map((video) => (
                  <li
                    key={video.id}
                    onClick={() => setSelectedVideo(video)}
                    className={`cursor-pointer p-4 rounded-md border 
                      ${selectedVideo?.id === video.id 
                        ? 'bg-[#1d202b] text-white' 
                        : 'bg-gray-100 text-gray-800'} 
                      hover:bg-[#1d202b] hover:text-white transition-colors`}
                  >
                    {video.title}
                  </li>
                ))}
              </ul>
            </div>

            {/* Main Video Container */}
            <div className="md:w-[70%] flex flex-col justify-center items-center">
              <div className="w-full max-w-3xl bg-white shadow-md rounded-lg p-6 min-h-[300px]">
                {selectedVideo && (
                  <h3 className="text-2xl font-bold mb-4 text-center">
                    {selectedVideo.title}
                  </h3>
                )}
                <div className="flex justify-center items-center h-full">
                  {selectedVideo ? (
                    <iframe
                      src={`https://drive.google.com/file/d/${selectedVideo.id}/preview`}
                      allow="autoplay"
                      className="w-full h-96 rounded-lg"
                      frameBorder="0"
                    ></iframe>
                  ) : (
                    <p className="text-center text-gray-600">
                      Please select a video from the playlist.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default GoogleDriveVideo;