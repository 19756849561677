import { useState } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom"; // Add this import

const TeamRegistrationForm = () => {
  const navigate = useNavigate();
  const [teamName, setTeamName] = useState("");
  const [country, setCountry] = useState("");
  const [members, setMembers] = useState([
    { 
      name: "", 
      facebook: "", 
      email: "", 
      phone: "", 
      profession: "", 
      school: "", 
      cv: null, 
      attendanceType: "" 
    },
  ]);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false); 

  const url = "https://script.google.com/macros/s/AKfycbzFgOVFBHMgXB6vUERnlQN9GXr1ElwtnuNtK-t3uwlx_k50cQulLlig6FaL6posRAdtTQ/exec";

  const openCloudinaryWidget = (index) => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: "dalagps9g",
        uploadPreset: "riadh_belgacem",
        multiple: false,
        folder: "team_registration_cvs",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          const uploadedCV = result.info.secure_url;
          handleMemberChange(index, "cv", uploadedCV);
        }
      }
    );
    widget.open();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return; // Prevent double submission
    setLoading(true);
    const formData = new FormData();
    formData.append("TeamName", teamName);
    formData.append("Country", country);
    
    members.forEach((member, index) => {
      formData.append(`Member${index + 1}Name`, member.name);
      formData.append(`Member${index + 1}Facebook`, member.facebook);
      formData.append(`Member${index + 1}Email`, member.email);
      formData.append(`Member${index + 1}Phone`, member.phone);
      formData.append(`Member${index + 1}Profession`, member.profession);
      formData.append(`Member${index + 1}School`, member.school);
      formData.append(`Member${index + 1}CV`, member.cv || "");
      formData.append(`Member${index + 1}AttendanceType`, member.attendanceType);
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const data = await response.text();
      console.log(data);
      setShowPopup(true);

      // Reset form
      setTeamName("");
      setCountry("");
      setMembers([{ 
        name: "", 
        facebook: "", 
        email: "", 
        phone: "", 
        profession: "", 
        school: "", 
        cv: null, 
        attendanceType: "" 
      }]);
    } catch (error) {
      console.error("Error:", error);
      alert("Registration failed. Please try again.");
    }finally{
      setLoading(false); 
    }
  };

  const handleMemberChange = (index, field, value) => {
    const newMembers = [...members];
    newMembers[index][field] = value;
    setMembers(newMembers);
  };

  const handleAddMember = () => {
    if (members.length < 3) {
      setMembers([...members, { 
        name: "", 
        facebook: "", 
        email: "", 
        phone: "", 
        profession: "", 
        school: "", 
        cv: null, 
        attendanceType: "" 
      }]);
    } else {
      alert("Maximum team size is 3 members");
    }
  };

  const handleRemoveMember = (index) => {
    const newMembers = members.filter((_, i) => i !== index);
    setMembers(newMembers);
  };

  const SuccessPopup = () => (
    <>
      <style>
        {`
          @keyframes checkmark {
            0% { transform: scale(0); opacity: 0; }
            50% { transform: scale(1.2); }
            100% { transform: scale(1); opacity: 1; }
          }

          @keyframes popup-in {
            0% { transform: scale(0.95); opacity: 0; }
            100% { transform: scale(1); opacity: 1; }
          }

          .animate-checkmark {
            animation: checkmark 0.6s ease-out forwards;
          }

          .animate-popup-in {
            animation: popup-in 0.3s ease-out forwards;
          }
        `}
      </style>
      
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 transform transition-all scale-95 animate-popup-in">
          <div className="text-center">
            <HiCheckCircle className="text-6xl text-green-500 mx-auto mb-4 animate-checkmark" />
            <h3 className="text-2xl font-bold text-gray-800 mb-2">Registration Successful!</h3>
            <p className="text-gray-600 mb-4">
              Your team registration has been received. We'll be in touch soon!
            </p>
            <button
              onClick={() => {
                setShowPopup(false);
                navigate("/GODS"); // Add the navigation here
              }}
              className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-6 rounded-lg transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-900 py-5">
      <div className="w-full max-w-3xl bg-gray-800 p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold font-poppins text-yellow-400 text-center mb-4">
          Team Registration
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="sm:px-24 px-16">
            <label className="block text-white mb-1">Team Name</label>
            <input
              type="text"
              className="w-full px-3 py-2 bg-gray-200 text-black rounded border border-gray-600 focus:outline-none ring-2 ring-[#a38f19]"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              required
            />
          </div>

          <div className="sm:px-24 px-16">
            <label className="block text-white mb-1">Country</label>
            <input
              type="text"
              className="w-full px-3 py-2 bg-gray-200 text-black rounded border border-gray-600 focus:outline-none ring-2 ring-[#a38f19]"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            />
          </div>

          {members.map((member, index) => (
            <div key={index} className="sm:px-24 px-16 pt-12 pb-8 rounded space-y-4">
              <h3 className="text-lg font-semibold text-yellow-400">Member {index + 1}</h3>

              {[
                { label: "Name", field: "name" },
                { label: "Facebook", field: "facebook" },
                { label: "Email", field: "email" },
                { label: "Phone", field: "phone" },
                { label: "Profession", field: "profession" },
                { label: "School", field: "school" }
              ].map(({ label, field }) => (
                <div key={field}>
                  <label className="block text-white mb-1">{label}</label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 bg-gray-200 text-black rounded border border-gray-600 focus:outline-none ring-2 ring-[#a38f19]"
                    value={member[field]}
                    onChange={(e) => handleMemberChange(index, field, e.target.value)}
                    required
                  />
                </div>
              ))}

              <div>
                <label className="block text-white mb-1">Attendance Type</label>
                <select
                  className="w-full px-3 py-2 bg-gray-200 text-black rounded border border-gray-600 focus:outline-none ring-2 ring-[#a38f19]"
                  value={member.attendanceType}
                  onChange={(e) => handleMemberChange(index, "attendanceType", e.target.value)}
                  required
                >
                  <option value="">Select Attendance Type</option>
                  <option value="Hackathon Only">Hackathon Only</option>
                  <option value="Hackathon with Workshops">Hackathon with Workshops</option>
                </select>
              </div>

              <div>
                <label className="block text-white mb-1">CV Upload</label>
                <button
                  type="button"
                  className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  onClick={() => openCloudinaryWidget(index)}
                >
                  Upload CV
                </button>
                {member.cv && (
                  <a
                    href={member.cv}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-yellow-400 block mt-2"
                  >
                    View Uploaded CV
                  </a>
                )}
              </div>

              {index > 0 && (
                <button
                  type="button"
                  className="sm:w-[160px] sm:h-[45px] w-[90px] py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  onClick={() => handleRemoveMember(index)}
                >
                  Remove Member
                </button>
              )}
            </div>
          ))}

          <div className="sm:px-24 px-16 flex gap-24 sm:gap-52">
            {members.length < 3 && (
              <button
                type="button"
                className="sm:w-1/2 sm:py-3 bg-blue-600 text-white rounded hover:bg-blue-600"
                onClick={handleAddMember}
              >
                Add Member
              </button>
            )}
             <button
        type="submit"
        disabled={loading}
        className={`bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-6 rounded-lg transition-colors ${
          loading ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        {loading ? "Submitting..." : "Register"}
      </button>
          </div>
        </form>
      </div>
      {showPopup && <SuccessPopup />}
    </div>
  );
};

export default TeamRegistrationForm;