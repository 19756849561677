import React from 'react'; 
import Navbar from '../Navbar/Navbar';
import '../Registration/RegistrationForm1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Day1 from './Day1';
import Day2 from './Day2';
import Footer from '../Footer/Footer';
import Workshops from './Workshops';

function Program() {
  return (
    <div className=''>
      <Navbar />
      <div className="flex justify-center gap-2">
        <div className="text-4xl font-bold pt-32 ">
        <h1 className="text-center text-[40px] font-semibold text-transparent  bg-clip-text bg-gradient-to-r from-purple-500 to-cyan-500 mb-2 pt-6">
          Schedual Overview
        </h1> </div>
      </div>
      <br />
      
      <br />
      <br />
      {/* Render both Day1 and Day2 */}
      <div className="day-schedules ">
        <Day1 />
        <Day2 />
      </div>
      <br />
      <Workshops />
      <Footer />
    </div>
  );
}

export default Program;
